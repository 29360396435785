.searchBox {
    background-color: #242424;
    color: black;
    display: flex;
    justify-content: center;
    position: relative;
    height: 0px;
  }
  
  .headerContainer {
    width: 100%;
    max-width: 1024px;
    margin: 20px 0px 100px 0px;
  }
  
  .headerContainer.listMode {
    margin: 20px 0px 0px 0px;
  }
  
  .headerList {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
  }
  
  .headerListItem {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .headerListItem.active {
    border: 1px solid white;
    padding: 10px;
    border-radius: 20px;
  }
  
  .headerDesc {
    margin: 20px 0px;
  }
  
  .headerBtn {
    background-color: #242424;
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .headerSearch {
    height: 100px;
    background-color: white;
    border: 3px solid #242424;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0px;
    border-radius: 5px;
    position: absolute;
    bottom: -25px;
    width: 100%;
    max-width: 1024px;
  }
  
  .headerIcon {
    color: #242424;
  }
  
  .headerSearchItem {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
  }
  
  .headerSearchInput {
    border: none;
    outline: none;
    font-size: large;
  }
  
  .headerSearchText {
    color: #242424;
    cursor: pointer;
    font-size: large;
  }
  
  .date {
    position: absolute;
    top: 60px;
    z-index: 2;
  }
  
  .options {
    z-index: 2;
    position: absolute;
    top: 60px;
    background-color: white;
    color: #242424;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
  
  .optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }
  
  .optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    color: black;
  }
  
  .optionCounterButton {
    width: 40px;
    height: 30px;
    border: 1px solid #0071c2;
    color: #0071c2;
    cursor: pointer;
    background-color: white;
  }
  
  .optionCounterButton:disabled {
    cursor: not-allowed;
  }