* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

  .home,
  .products{
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  
  .products {
    background-image: url('/public/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
   display: flex;
   flex-direction: column;
   margin: auto;
   margin-top: 100px;
   background: #fff;
   padding-bottom: 30px;
   width: 600px;

   .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
   }

   .text{
    color: #242424;
    font-size: 48px;
    font-weight: 700;
   }

   .underline{
    width: 61px;
    height: 6px;
    background: #242424;
    border-radius: 9px;
   }

   .inputs{
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
   }

   .input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
   }

   .input img{
    margin: 0px 30px;
   }

   .input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
   }

   .forgot-password{
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
   }

   .forgot-password span{
    color: #242424;
    cursor: pointer;
   }

   .submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
   }

   .submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background: #242424;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
   }

   .gray{
    background: #EAEAEA;
    color: #676767;
   }
  }